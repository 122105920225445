import * as $ from 'jquery';

$(document).ready(function(){
    let slider = $('.slider'),
        menu = $('.menu-mobile'),
        scrollAnchorList = document.querySelectorAll('.menu-link');

    $('input[type=tel]').inputmask({"mask": "+7 (999) 999-99-99"});

    $('.study__slider').slick({
        infinite: true,
        loop:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        arrows:true,
        dots:false
    });

    $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let index = nextSlide + 1;
        $(".slick-slide").removeClass('active');
        setTimeout(function(){
            $("[data-slick-index='"+index+"'").addClass('active');
        },300);
    });

    setTimeout(function(){
        $("[data-slick-index=1]").addClass('active');
    }, 300);


    scrollAnchorList.forEach(function(elem){
        elem.addEventListener('click', function(event){
            if(event.target.hash){
                event.preventDefault();
                let ID = event.target.hash.substring(1),
                    ANCHOR = ID,
                    ANCHOR_BOX = document.getElementById(ANCHOR);

                window.scrollTo({
                    top: ANCHOR_BOX.offsetTop - 70,
                    behavior: "smooth"
                });
            }
            if(window.innerWidth < 992){
                $(menu).toggleClass('active');
            }
        })
    });

    $('.js-modal').click(function(){
        $('#feedback').toggleClass('active');
        $('.wrapper').toggleClass('blur');
        $('body').toggleClass('scroll');
    });
    $('.modal-closer').click(function(){
        $('#feedback').toggleClass('active');
        $('.wrapper').toggleClass('blur');
        $('body').toggleClass('scroll');
    });
    $('.js-menu').click(function(){
        $(menu).toggleClass('active');
    });

    $('#top').click(function(){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })
});
$(document).on('click', '.key-item_more', function(){
    $(this).toggleClass('active');
    $(this).siblings('.key-item_list').toggleClass('active');
});
$(document).on('scroll', function(){
    if(window.scrollY > window.innerHeight / 2){
        $('#top').show(200);
    }else{
        $('#top').hide(200);
    }
});

$(document).on("click", '.variant__item', function(event){
    let id = $(this).data('id'),
        ANCHOR_BOX = document.getElementById('kitchen');

    $('[data-id="slide_'+id+'"]').addClass('kitchen__wrapper_active');
    window.scrollTo({
        top: ANCHOR_BOX.offsetTop + 70,
        behavior: "smooth"
    });

});

$(document).on("click", '.kitchen__item_back', function(){
    let parent = $(this).parents('.kitchen__wrapper');
    $(parent).removeClass('kitchen__wrapper_active');
});

$(document).on("click", '.study-mobile__arrow', function(){
    let parent = $(this).parents('.study-mobile__block'),
        list = $(parent).children('.study-mobile__list');

    //$('.study-mobile__arrow').removeClass('study-mobile__arrow_active');
    //$('.study-mobile__list').removeClass('study-mobile__list_active');

    $(this).toggleClass('study-mobile__arrow_active')
    $(list).toggleClass('study-mobile__list_active');
});
